import uikit from "./uikit.js"
import icons from "../icons/icons"
import components from "./components.js"
import $ from "jquery"
import Swiper, { Navigation, Lazy } from "swiper"
Swiper.use([Navigation, Lazy])
import LazyLoad from "vanilla-lazyload"
import Scrollbar, { ScrollbarPlugin } from "smooth-scrollbar"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { TimelineMax } from "gsap/gsap-core"
import anime from "animejs/lib/anime.es.js"
import "movinwords/dist/movinwords.css"
import Movinwords from "movinwords/dist/movinwords"
import "select2"
import "select2/dist/css/select2.css"
import { CountUp } from "countup.js"
gsap.registerPlugin(ScrollTrigger)
import * as ClipboardJS from "clipboard"

uikit()
components()
function uikitPageDropdown() {
  $(".js-uikit-pages-dropdown").on("click", (e) => {
    $(".front-ui-dropdown").toggleClass("active")
  })
}
uikitPageDropdown()

const scroller = document.querySelector(".js-smooth-scrollbar")

var scrollbarb = Scrollbar.init(scroller, {})
var lazyLoadInstance = new LazyLoad({})
var app = {
  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },

  iconSpriteFn() {
    icons.forEach(iconSpriteFn)
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite")
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          "<span class='icon icon-font'>" +
          item.iconSvg +
          "</span>" +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>"
      }

      const icon = document.querySelectorAll(".icon")
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id")
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg
          }
        })
      }
    }
    const uiContent = document.querySelectorAll(
      ".js-uikit-content .uikit__item"
    )
    const uiSide = document.querySelector(".js-uikit-side")
    uiContent.forEach((uiContent, i) => {
      let uiContentName = uiContent.getAttribute("id")
      const uiLinkItem = document.createElement("li")
      const uiLinkItemA = document.createElement("a")
      uiLinkItemA.setAttribute("href", `#${uiContentName}`)
      uiLinkItem.classList.add("uikit__side-item")
      uiLinkItem.appendChild(uiLinkItemA)
      uiLinkItemA.innerHTML = uiContentName.replace(/-/g, " ")

      let uiContentParent = uiContent.getAttribute("data-ui-parent")

      uiSide.querySelectorAll(".uikit__side-field").forEach((element) => {
        var title = element.querySelector(".uikit__side-title")
        if (title.innerHTML === uiContentParent) {
          element.querySelector(".uikit__side-list").appendChild(uiLinkItem)
        }
      })
    })
  },

  smoothScrollbarFn() {
    var header = document.querySelector("header")
    const subheaderBg = document.querySelector(".subheader__bg")
    const counter = document.querySelector(".js-counter-parent")

    if (header && scroller) {
      scrollbarb.addListener(({ offset }) => {
        if (offset.y > 100) {
          header.classList.add("header--sticky")
          if (scrollbarb._momentum.y > 0) {
            header.classList.add("header--unpinned")
          } else if (scrollbarb._momentum.y < 0) {
            header.classList.remove("header--unpinned")
          }
        } else {
          header.classList.remove("header--sticky")
        }
        var counterParent = $(".js-counter-parent")
        if (counterParent.length > 0) {
          var counterTop = counterParent.offset().top - 300
          if (scrollbarb._momentum.y > 0) {
            if (counterTop < 0 && counterTop > -75) {
              document
                .querySelectorAll(".js-counter-item")
                .forEach((element) => {
                  var dataCount = element.getAttribute("data-count")
                  var id = element.getAttribute("id")
                  var countUpV = new CountUp(id, dataCount, {
                    useGrouping: false,
                    suffix: element.getAttribute("data-suffix"),
                  })
                  countUpV.start()
                })
            }
          }
        }
      })
    }

    ScrollTrigger.scrollerProxy(".scroll-content", {
      scrollTop(value) {
        if (arguments.length) {
          scrollbarb.scrollTop = value
        }
        return scrollbarb.scrollTop
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      },
    })
    if (scroller) {
      scrollbarb.addListener(ScrollTrigger.update)
    }
    ScrollTrigger.defaults({ scroller: scroller })
  },

  headerWhiteFn() {
    var header = document.querySelector("header")
    if (header) {
      const subheaderBg = document.querySelector(".subheader__bg")
      if (subheaderBg) {
        header.classList.add("header--white")
      } else {
        header.classList.remove("header--white")
      }
    }
  },
  mobilemenubuttonsfn() {
    var header = document.querySelector("header")
    if (header) {
      const headerTitle = document.querySelectorAll(".js-header-nav-title")
      headerTitle.forEach((el) => {
        el.addEventListener("click", function () {
          const elParent = el.closest(".mobile-menu__item")
          if (elParent.classList.contains("opened")) {
            elParent.classList.remove("opened")
            elParent.querySelector(".mobile-menu__dropdown").style.height =
              0 + "px"
          } else {
            document
              .querySelectorAll(".mobile-menu__item.opened")
              .forEach((element) => {
                element.classList.remove("opened")
                element.querySelector(".mobile-menu__dropdown").style.height =
                  0 + "px"
              })
            elParent.classList.add("opened")
            const listHeight = elParent.querySelector(
              ".js-header-nav-list"
            ).offsetHeight
            elParent.querySelector(".mobile-menu__dropdown").style.height =
              listHeight + "px"
          }
        })
      })

      const headerTopBtn = document.querySelectorAll(
        ".header__top-button, .header__top-button__overlay"
      )

      headerTopBtn.forEach((btn) => {
        btn.addEventListener("click", () => {
          if (
            btn
              .closest(".header__top-button__parent")
              .classList.contains("active")
          ) {
            btn
              .closest(".header__top-button__parent")
              .classList.remove("active")
            if (header.classList.contains("header--white")) {
              header.classList.remove("header--secondary")
            }
          } else {
            document
              .querySelectorAll(".header__top-button__parent.active")
              .forEach((btnActive) => {
                btnActive.classList.remove("active")
              })
            btn.closest(".header__top-button__parent").classList.add("active")
            if (header.classList.contains("header--white")) {
              header.classList.add("header--secondary")
            }
          }
        })
      })
      const headerListItem = document.querySelectorAll(".header__list-item")

      headerListItem.forEach((item) => {
        item.addEventListener("mouseenter", () => {
          if (header.classList.contains("header--white")) {
            header.classList.add("header--secondary-menu")
          }
        })
        item.addEventListener("mouseleave", () => {
          if (header.classList.contains("header--white")) {
            header.classList.remove("header--secondary-menu")
          }
        })
      })
    }
  },

  cookieAlertFn() {
    const cookieAlertClose = document.querySelector(".js-cookie-close")
    cookieAlertClose.addEventListener("click", () => {
      document.querySelector(".js-cookie").classList.add("disable")
    })
    if (document.querySelector(".lookingforjob")) {
      document
        .querySelector(".lookingforjob input")
        .addEventListener("keypress", (event) => {
          if (event.key === "Enter") {
            event.preventDefault()
            document.querySelector(".lookingforjob .hero__search-btn").click()
          }
        })
      document
        .querySelector(".lookingforjob .hero__search-btn")
        .addEventListener("click", () => {})
    }
  },
  headerSearchFn() {
    const searchBtn = document.querySelector(".js-search-btn")
    const searchWrp = document.querySelector(".js-main-search")
    const closeSearchBtn = document.querySelector(".js-close-search-btn")
    const closeSearchBtnOverlay = document.querySelector(
      ".js-close-search-btn-overlay"
    )
    if (searchBtn) {
      searchBtn.addEventListener("click", () => {
        searchWrp.classList.add("opened")
      })
      closeSearchBtn.addEventListener("click", () => {
        searchWrp.classList.remove("opened")
      })
      closeSearchBtnOverlay.addEventListener("click", () => {
        searchWrp.classList.remove("opened")
      })
    }
  },
  resourcesCircleAnimFn() {
    if (document.querySelector(".js-resources-circle-anim")) {
      function indexCirclesScrollTrigger() {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".js-resources-circle-anim",
            scroller: ".scroll-content",
            start: "top-=200 50%",
            end: "top+=320 50%",
            scrub: true,
          },
        })
        tl.from(".resources__circle__left--first", { x: "33vw", y: "25vh" }, 0)
          .from(
            ".resources__circle__left--second",
            { x: "33vw", y: "-25vh" },
            0
          )
          .from(".resources__circle__left--third", { x: "25vw" }, 0)
          .from(
            ".resources__circle__left--fourth",
            { x: "15vw", y: "-10vh" },
            0
          )
          .from(".resources__circle__left--fifth", { x: "10vw", y: "15vh" }, 0)
          .from(
            ".resources__circle__right--first",
            { x: "-35vw", y: "15vh" },
            0
          )
          .from(
            ".resources__circle__right--second",
            { x: "-33vw", y: "-15vh" },
            0
          )
          .from(
            ".resources__circle__right--third",
            { x: "-20vw", y: "-10vh" },
            0
          )
          .from(
            ".resources__circle__right--fourth",
            { x: "-10vw", y: "-15vh" },
            0
          )
          .from(
            ".resources__circle__right--fifth",
            { x: "-15vw", y: "10vh" },
            0
          )
      }
      ScrollTrigger.matchMedia({
        "(min-width:991px)": function () {
          indexCirclesScrollTrigger()
        },
      })
    }
  },
  inspirationsStickyFn() {
    const trigger = document.querySelector(".js-inspirations-sticky")
    if (trigger) {
      let start = "top 10%"
      let end = "bottom+=40 100%"
      function inspirationStickyScrollTrigger() {
        gsap.to("body", {
          scrollTrigger: {
            trigger: trigger,
            scroller: ".scroll-content",
            start: start,
            end: end,
            pin: ".inspirations__sticky-item",
            pinSpacing: false,
            scrub: true,
          },
        })
      }
      ScrollTrigger.matchMedia({
        "(min-width:991px)": function () {
          ;(start = "top 10%"),
            (end = "bottom+=40 100%"),
            inspirationStickyScrollTrigger()
        },
      })
    }
  },
  aboutImagesFn() {
    if (document.querySelector(".js-about-images")) {
      if (window.matchMedia("(min-width: 993px)").matches) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".js-about-images",
            scroller: ".scroll-content",
            start: "top-=100 50%",
            end: "center+=200 50%",
            scrub: true,
            duration: 400,
          },
        })
        tl.from(".about__images-item--first", {
          x: "5%",
          y: "-10%",
          opacity: 0,
        })
          .from(".about__images-item--second", {
            x: "20%",
            y: "60%",
            opacity: 0,
          })
          .from(".about__images-item--third", {
            x: "-15%",
            y: "-10%",
            opacity: 0,
          })
      }
    }
  },
  aboutCirclesFn() {
    if (document.querySelector(".js-about-circles")) {
      if (window.matchMedia("(min-width: 993px)").matches) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: ".js-about-circles",
            scroller: ".scroll-content",
            start: "center 50%",
            end: "bottom+=700 50%",
            scrub: true,
            pin: true,
          },
        })
        tl.to(".about-circle__item--first", { x: "45%", y: "-25%" }, 0)
          .to(".about-circle__item--second", { x: "0%", y: "51%" }, 0)
          .to(".about-circle__item--third", { x: "-44%", y: "-25%" }, 0)
        //
        document
          .querySelectorAll(".about-circle__item .about-circle__txt")
          .forEach((element) => {
            tl.to(
              element,
              {
                marginTop: 15,
                maxHeight: 205,
              },
              0
            )
          })

        tl.to(
          ".about-circle__item--first .about-circle__bg",
          { scale: "1.08" },
          0
        )
          .to(
            ".about-circle__item--second .about-circle__bg",
            { scale: "1.1" },
            0
          )
          .to(
            ".about-circle__item--third .about-circle__bg",
            { scale: "1.3" },
            0
          )
      }
    }
  },
  cursorFn() {
    const btn = document.querySelectorAll(".js-magnet-item")
    const dragMoveField = document.querySelectorAll(".js-drag-move-field")
    const linkField = document.querySelectorAll(".js-link-field")
    const watchField = document.querySelectorAll(".js-watch-field")
    const hoverField = document.querySelectorAll(".js-hover-field")
    const cursor = document.querySelector(".cursor")
    const cursorInvert = document.querySelectorAll(".js-invert-field")

    const update = function (e) {
      const span = this.querySelector("span")
      if (e.type === "mouseleave") {
        span.style.cssText = ""
      } else {
        const { offsetX: x, offsetY: y } = e,
          { offsetWidth: width, offsetHeight: height } = this,
          walk = 20,
          xWalk = (x / width) * (walk * 2) - walk,
          yWalk = (y / height) * (walk * 2) - walk

        span.style.cssText = `transform: translate(${xWalk}px, ${yWalk}px);`
      }
    }

    btn.forEach((b) => b.addEventListener("mousemove", update))
    btn.forEach((b) => b.addEventListener("mouseleave", update))
    dragMoveField.forEach((e) => {
      e.addEventListener("mouseenter", () => {
        cursor.classList.add("is-drag")
        let cursorText = e.getAttribute("data-cursor-text")
        cursor.querySelector(".cursor__txt").innerHTML = cursorText
      })
      e.addEventListener("mouseleave", () => {
        cursor.classList.remove("is-drag")
      })
    })
    linkField.forEach((e) => {
      e.addEventListener("mouseenter", () => {
        cursor.classList.add("is-link")
        let cursorText = e.getAttribute("data-cursor-text")
        cursor.querySelector(".cursor__txt").innerHTML = cursorText
      })
      e.addEventListener("mouseleave", () => {
        cursor.classList.remove("is-link")
        cursor.querySelector(".cursor__txt").innerHTML = ""
      })
    })
    watchField.forEach((e) => {
      e.addEventListener("mouseenter", () => {
        cursor.classList.add("is-watch")
        let cursorText = e.getAttribute("data-cursor-text")
        cursor.querySelector(".cursor__txt").innerHTML = cursorText
      })
      e.addEventListener("mouseleave", () => {
        cursor.classList.remove("is-watch")
        cursor.querySelector(".cursor__txt").innerHTML = ""
      })
    })
    var tableLinkHover = document.querySelectorAll("table a")
    hoverField.forEach((e) => {
      e.addEventListener("mouseenter", () => {
        cursor.classList.add("is-hover")
      })
      tableLinkHover.forEach((element) => {
        element.addEventListener("mouseenter", () => {
          cursor.classList.add("is-hover")
        })
        element.addEventListener("mouseleave", () => {
          cursor.classList.remove("is-hover")
        })
      })
      e.addEventListener("mouseleave", () => {
        cursor.classList.remove("is-hover")
      })
    })
    cursorInvert.forEach((e) => {
      e.addEventListener("mouseenter", () => {
        cursor.classList.add("is-invert")
      })
      e.addEventListener("mouseleave", () => {
        cursor.classList.remove("is-invert")
      })
    })

    gsap.set(cursor, {
      xPercent: -50,
      yPercent: -50,
    })
    document.addEventListener("pointermove", movecursor)
    function movecursor(e) {
      gsap.to(cursor, {
        duration: false,
        x: e.clientX,
        y: e.clientY,
      })
    }
  },

  heroCirclesFn() {
    let animeRandomX = anime.random(-270, 620)
    let animeRandomY = anime.random(-270, 270)
    function circleSm() {
      anime({
        targets: ".js-main-bg-circle-sm",
        translateX: function () {
          return anime.random(-220, 320)
        },
        translateY: function () {
          return anime.random(-270, 270)
        },
        easing: "cubicBezier(.5, .05, .1, .3)",
        duration: 4500,
        complete: circleSm,
      })
    }
    function circleMd() {
      anime({
        targets: ".js-main-bg-circle-md",
        translateX: function () {
          return anime.random(-320, 150)
        },
        translateY: function () {
          return anime.random(-270, -50)
        },
        easing: "cubicBezier(.5, .05, .1, .3)",
        duration: 4500,
        complete: circleMd,
      })
    }
    function circleLg() {
      anime({
        targets: ".js-main-bg-circle-lg",
        translateX: function () {
          return anime.random(-270, 320)
        },
        translateY: function () {
          return anime.random(0, 270)
        },
        easing: "cubicBezier(.5, .05, .1, .3)",
        duration: 5000,
        complete: circleLg,
      })
    }

    circleSm()
    circleMd()
    circleLg()
  },
  movinWordsFn() {
    const mw = new Movinwords({
      el: ".js-text-animation",
      autostart: false,
      delay: "150",
      duration: "1300",
      transition: "revealInBottom",
      offset: "130",
      wordSpacing: "10",
      highlight: {
        classname: "highlight",
        tag: "strong",
        words: ["many", "more", "to", "hire", "the", "best", "talent"],
      },
    })

    mw.start()
  },
  heroLoadedFn() {
    if (document.querySelector(".hero")) {
      setTimeout(() => {
        document
          .querySelector(".hero__play , .video-content__play")
          .classList.add("loaded")
        document.querySelector(".hero__scroll").classList.add("loaded")
        document.querySelector(".hero__title").classList.add("loaded")
        document.querySelector(".hero__mail").classList.add("loaded")
        document.querySelector(".hero__search").classList.add("loaded")
      }, 600)
    }
  },
  unLoadedFn() {
    var header = document.querySelector("header")
    if (document.querySelector(".subheader")) {
      setTimeout(() => {
        document.querySelector(".subheader").classList.remove("unLoaded")
      }, 500)
    }
    if (header) {
      setTimeout(() => {
        header.classList.remove("unLoaded")
      }, 600)
    }
    if (
      document.querySelector(".with-main-bg") ||
      document.querySelector(".subheader .main-bg")
    ) {
      document.querySelectorAll(".main-bg__inner").forEach((element) => {
        element.classList.remove("unLoaded")
      })
      setTimeout(() => {
        document.querySelectorAll(".main-bg__circle").forEach((element) => {
          element.style.transitionDelay = "0s"
        })
      }, 1200)
    }
    if (document.querySelector(".subheader")) {
      document.querySelector(".main-bg--main").style.display = "none"
    } else {
      document.querySelector(".main-bg--main").style.display = ""
    }
  },
  mainPopupsFn() {
    const mainPopupBtn = document.querySelector(".js-main-popup-btn")
    const mainPopupFormBtn = document.querySelectorAll(".js-open-form")
    if (mainPopupBtn) {
      mainPopupBtn.addEventListener("click", () => {
        mainPopupBtn.parentNode.classList.toggle("opened")
      })
      mainPopupFormBtn.forEach((e) => {
        e.addEventListener("click", () => {
          document
            .querySelector(".js-main-popups-form")
            .classList.toggle("opened")
        })
      })
    }
  },
  expertiseSliderFn() {
    const i = Swiper.prototype.init
    Swiper.prototype.init = function () {
      this.touchEventsData.formElements = "*"
      i.call(this)
    }
    const expertiseSwiper = new Swiper(".js-expertise-slider", {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: 1.35,
      speed: 700,
      spaceBetween: 40,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          spaceBetween: 15,
        },
        769: {
          slidesPerView: 1.35,
          spaceBetween: 40,
        },
      },
      on: {
        touchStart: function (e) {
          e.el.classList.add("expertise-slider-touch")
        },
        touchEnd: function (e) {
          e.el.classList.remove("expertise-slider-touch")
        },
        slideChange: function (e) {
          if (window.matchMedia("(min-width: 993px)").matches) {
            if (document.querySelector(".expertise__content")) {
              if (e.activeIndex > 0) {
                document
                  .querySelector(".expertise__content")
                  .classList.add("disabled")
              } else {
                document
                  .querySelector(".expertise__content")
                  .classList.remove("disabled")
              }
            }
          }
        },
      },
    })
  },
  testimonialsSliderFn() {
    const testimonialsSwiper = new Swiper(".js-testimonials-slider", {
      slidesPerView: 2.3,
      speed: 700,
      spaceBetween: 40,
      preloadImages: false,
      lazy: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          autoHeight: true,
        },
        769: {
          autoHeight: false,
        },
        991: {
          slidesPerView: 1.7,
        },
        1024: {
          slidesPerView: 2.3,
        },
      },
    })
  },
  indexTextAnimFn() {
    window.addEventListener("mousemove", handleMouseMove)
    window.addEventListener("resize", handleWindowResize)

    const spansSlow = document.querySelectorAll(".spanSlow")
    const spansFast = document.querySelectorAll(".spanFast")

    let width = window.innerWidth

    function handleMouseMove(e) {
      let normalizedPosition = e.pageX / (width / 2) - 1
      let speedSlow = 100 * normalizedPosition
      let speedFast = 200 * normalizedPosition
      spansSlow.forEach((span) => {
        span.style.transform = `translate(${speedSlow}px)`
      })
      spansFast.forEach((span) => {
        span.style.transform = `translate(${speedFast}px)`
      })
    }
    function handleWindowResize() {
      width = window.innerWidth
    }
  },

  select2OptionsFn() {
    // Select2 always open below option
    ;(function ($) {
      var Defaults = $.fn.select2.amd.require("select2/defaults")

      $.extend(Defaults.defaults, {
        dropdownPosition: "auto",
      })

      var AttachBody = $.fn.select2.amd.require("select2/dropdown/attachBody")

      var _positionDropdown = AttachBody.prototype._positionDropdown

      AttachBody.prototype._positionDropdown = function () {
        var $window = $(window)

        var isCurrentlyAbove = this.$dropdown.hasClass(
          "select2-dropdown--above"
        )
        var isCurrentlyBelow = this.$dropdown.hasClass(
          "select2-dropdown--below"
        )

        var newDirection = null

        var offset = this.$container.offset()

        offset.bottom = offset.top + this.$container.outerHeight(false)

        var container = {
          height: this.$container.outerHeight(false),
        }

        container.top = offset.top
        container.bottom = offset.top + container.height

        var dropdown = {
          height: this.$dropdown.outerHeight(false),
        }

        var viewport = {
          top: $window.scrollTop(),
          bottom: $window.scrollTop() + $window.height(),
        }

        var enoughRoomAbove = viewport.top < offset.top - dropdown.height
        var enoughRoomBelow = viewport.bottom > offset.bottom + dropdown.height

        var css = {
          left: offset.left,
          top: container.bottom,
        }

        // Determine what the parent element is to use for calciulating the offset
        var $offsetParent = this.$dropdownParent

        // For statically positoned elements, we need to get the element
        // that is determining the offset
        if ($offsetParent.css("position") === "static") {
          $offsetParent = $offsetParent.offsetParent()
        }

        var parentOffset = $offsetParent.offset()

        css.top -= parentOffset.top
        css.left -= parentOffset.left

        var dropdownPositionOption = this.options.get("dropdownPosition")

        if (
          dropdownPositionOption === "above" ||
          dropdownPositionOption === "below"
        ) {
          newDirection = dropdownPositionOption
        } else {
          if (!isCurrentlyAbove && !isCurrentlyBelow) {
            newDirection = "below"
          }

          if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
            newDirection = "above"
          } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
            newDirection = "below"
          }
        }

        if (
          newDirection == "above" ||
          (isCurrentlyAbove && newDirection !== "below")
        ) {
          css.top = container.top - parentOffset.top - dropdown.height
        }

        if (newDirection != null) {
          this.$dropdown
            .removeClass("select2-dropdown--below select2-dropdown--above")
            .addClass("select2-dropdown--" + newDirection)
          this.$container
            .removeClass("select2-container--below select2-container--above")
            .addClass("select2-container--" + newDirection)
        }

        this.$dropdownContainer.css(css)
      }
    })(window.jQuery)
    $(".js-select").each(function () {
      $(this)
        .find("select")
        .select2({
          dropdownParent: $(this).closest(".js-select"),
          placeholder: $(this).attr("data-placeholder"),
          dropdownPosition: "below",
        })

      $(this)
        .find("select")
        .on("select2:open", function (e) {
          setTimeout(() => {
            var scrollbarSelect = Scrollbar.init(
              document.querySelector(".select2-results__options"),
              {}
            )
          }, 1)
        })

      $(this)
        .find("select")
        .on("select2:closing", function (e) {
          var scrollbarSelect = Scrollbar.init(
            document.querySelector(".select2-results__options"),
            {}
          )
          scrollbarSelect.destroy()
        })
    })
  },
  positionCardContentScroll() {
    var positionCard = document.querySelectorAll(".position-card__content")
    positionCard.forEach((element) => {
      var scrollbarJobCard = Scrollbar.init(element, {})
    })
  },
  mainSliderFn() {
    const mainSwiper = new Swiper(".js-main-slider", {
      slidesPerView: 2,
      spaceBetween: 40,
      loop: false,
      centeredSlides: true,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        320: {
          slidesPerView: 1.3,
          spaceBetween: 20,
        },
        769: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
      },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    })
  },
  modalFn() {
    $("body").on("click", ".js-open-modal", function (e) {
      e.preventDefault()
      $(".overlay").addClass("modal-overlay")
      $(".overlay").addClass("active")
      var id = $(this).data("modal-id")
      $('.js-modal[data-modal-id="modal-' + id + '"]').addClass("active")
    })
    $(".js-close-modal").on("click", function () {
      $(".overlay").removeClass("modal-overlay")
      $(".overlay").removeClass("active")
      $(".js-modal").removeClass("active")
    })
    $("body").on("click", ".modal-overlay", function () {
      $(".overlay").removeClass("modal-overlay")
      $(".overlay").removeClass("active")
      $(".js-modal").removeClass("active")
    })
  },
  accordionFn() {
    $("body").on("click", ".js-accordion-trigger", function () {
      $(this).toggleClass("active")
      const accordionGroup = $(this).closest(".accordion-wrapper")
      const item = $(this).closest(".accordion-item")
      let multipleShow = false

      if (accordionGroup.data("multiple-show") == true) {
        multipleShow = true
      } else {
        $(".js-accordion-trigger").not(this).removeClass("active")
      }
      let content = item.find(".accordion-calc")
      let contentHeight = content.outerHeight(true)
      if (item.hasClass("active") && !$(this).hasClass("force-open")) {
        item.find(".accordion-content").css("height", 0 + "px")
        item.removeClass("active")
      } else {
        if (!multipleShow) {
          accordionGroup.children(".accordion-item").removeClass("active")
          accordionGroup
            .children(".accordion-item")
            .find(".accordion-content")
            .css("height", 0 + "px")
        }
        item.addClass("active")
        item.find(".accordion-content").css("height", contentHeight)
      }
    })

    $(".accordion-item.opened .js-accordion-trigger").trigger("click")
  },
  similiarJobSliderFn() {
    const similiarJobSwiper = new Swiper(".js-similiar-job-slider", {
      slidesPerView: 1.5,
      spaceBetween: 40,
      loop: true,
      centeredSlides: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: "auto",
        },
        991: {
          slidesPerView: 1.5,
        },
      },
    })
  },
  listGridViewFn() {
    $(".js-grid-view").on("click", function () {
      $(".js-list-view").removeClass("active")
      $(this).addClass("active")
      $(".job-card").removeClass("job-card--detailed")
    })
    $(".js-list-view").on("click", function () {
      $(".js-grid-view").removeClass("active")
      $(this).addClass("active")
      $(".job-card").addClass("job-card--detailed")
    })
    if (
      document.querySelector(".browse-our-jobs__content") ||
      document.querySelector(".similiar-job-slider")
    ) {
      if (window.matchMedia("(max-width: 991px)").matches) {
        $(".job-card").addClass("job-card--detailed")
      } else {
        $(".job-card").removeClass("job-card--detailed")
      }
    }
  },
  menuButtonFn() {
    const menuBtn = document.querySelector(".js-menu-button")
    const menu = document.querySelector(".js-menu")
    const header = document.querySelector(".header")

    if (menuBtn) {
      menuBtn.addEventListener("click", function () {
        this.classList.toggle("opened")
        menu.classList.toggle("opened")
        header.classList.toggle("menu-opened")
        document.querySelector("html").classList.toggle("menu-opened")
        document.querySelector("body").classList.toggle("menu-opened")
      })
      document.querySelectorAll(".js-menu a").forEach((element) => {
        element.addEventListener("click", () => {
          menuBtn.classList.remove("opened")
          menu.classList.remove("opened")
          header.classList.remove("menu-opened")
          document.querySelector("html").classList.remove("menu-opened")
          document.querySelector("body").classList.remove("menu-opened")
        })
      })
    }
  },
  applyFilterFn() {
    var filterSideElemScroll = document.querySelectorAll(
      ".js-filter-side-inner-scroll"
    )
    if (document.querySelector(".browse-our-jobs__selected__cards")) {
      var scrollbarFilterSelected = Scrollbar.init(
        document.querySelector(".browse-our-jobs__selected__cards"),
        {
          alwaysShowTracks: true,
        }
      )
    }

    filterSideElemScroll.forEach((element) => {
      var scrollbarFilterInner = Scrollbar.init(element, {})
    })
    if (window.matchMedia("(min-width: 993px)").matches) {
      const parent = $(".product-filter-static")

      if (parent.length > 0) {
        const trigger = document.querySelector(".browse-our-jobs__content")
        let start = "top 1%"
        let end = "bottom+=40 100%"
        gsap.to("body", {
          scrollTrigger: {
            trigger: trigger,
            scroller: ".scroll-content",
            start: start,
            end: end,
            pin: ".product-filter",
            pinSpacing: false,
            scrub: true,
          },
        })
        var parentT = parent.offset().top
        var r = document.querySelector(".product-filter-static")
        var rs = getComputedStyle(r)
        var getH = rs.getPropertyValue("--maxheight")
        var scrollbarInner = Scrollbar.init(
          document.querySelector(".product-filter-static"),
          {}
        )
        scrollbarb.addListener(({ offset }) => {
          if (offset.y > parentT) {
            var headerH = $(".header").outerHeight()
            if (scrollbarb._momentum.y > 0) {
              parent.css("marginTop", 0)
              r.style.setProperty("--maxheight", getH)
              scrollbarInner.update()
            } else if (scrollbarb._momentum.y < 0) {
              parent.css("marginTop", headerH)
              r.style.setProperty("--maxheight", `calc(${getH} - ${headerH}px)`)
              scrollbarInner.update()
            }
          } else {
            parent.css("marginTop", 0)
            r.style.setProperty("--maxheight", getH)
            scrollbarInner.update()
          }
        })
      }
    } else {
      if ($(".product-filter").length > 0) {
        var scrollbarInner = Scrollbar.init(
          document.querySelector(".product-filter-static"),
          {}
        )
        var headerH = $(".header").outerHeight()

        scrollbarb.addListener(({ offset }) => {
          if ($(".product-filter").length > 0) {
            document.querySelector(".product-filter").style.top =
              offset.y + "px"
            document.querySelector(".product-filter").style.left =
              offset.x + "px"
            if (scrollbarb._momentum.y > 0) {
              $(".product-filter__top-mobile").css("marginTop", 0)
              scrollbarInner.update()
            } else if (scrollbarb._momentum.y < 0) {
              $(".product-filter__top-mobile").css("marginTop", headerH - 25)
              scrollbarInner.update()
            }
          }
        })
        $(".js-close-product-filter").on("click", function () {
          $(".product-filter").removeClass("active")
        })
        $(".browse-filter-btn").on("click", function () {
          $(".product-filter").addClass("active")
        })
      }
    }
  },
  letsChatFn() {
    $(".js-open-popup-form").on("click", () => {
      $(".js-main-popup-btn").trigger("click")
      // $(".js-open-form").trigger("click")
    })
    $(".js-open-popup-form-2").on("click", () => {
      $(".js-main-popup").addClass("opened")
      $(".js-main-popups-form").addClass("opened")
    })
  },
  sortList() {
    const sortByBtn = document.querySelector(".js-filter-btn")

    if (sortByBtn) {
      $(".resources__tab-sort__dropdown-item").on("click", function () {
        if (!this.classList.contains("active")) {
          $(".resources__tab-sort__dropdown-item").removeClass("active")
          this.classList.add("active")
        }
      })
      window.addEventListener("click", function (e) {
        if (
          document
            .querySelector(".resources__tab__right__wrapper")
            .contains(e.target)
        ) {
          sortByBtn.parentNode.classList.toggle("opened")
        } else {
          sortByBtn.parentNode.classList.remove("opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (
          document
            .querySelector(".resources__tab-sort__dropdown")
            .contains(e.target)
        ) {
          document
            .querySelector(".resources__tab-sort__dropdown")
            .parentNode.classList.add("opened")
        }
      })
    }

    var list = $(".sort-list")
    $(".AtoZ").on("click", function () {
      list
        .children()
        .detach()
        .sort(function (a, b) {
          return $(a).text().localeCompare($(b).text())
        })
        .appendTo(list)
    })
    $(".ZtoA").on("click", function () {
      list
        .children()
        .detach()
        .sort(function (a, b) {
          return $(b).text().localeCompare($(a).text())
        })
        .appendTo(list)
    })
    $(".newest").on("click", function () {
      list
        .children()
        .sort(function (a, b) {
          return $(b).attr("data-sort").localeCompare($(a).attr("data-sort"))
        })
        .appendTo(list)
    })
    $(".oldest").on("click", function () {
      list
        .children()
        .sort(function (a, b) {
          return $(a).attr("data-sort").localeCompare($(b).attr("data-sort"))
        })
        .appendTo(list)
    })
  },
  copyClipboardFn() {
    const clipboard = new ClipboardJS(".js-clipboard", {
      text: function (trigger) {
        return trigger.getAttribute("data-clipboard-text")
      },
    })
    clipboard.on("success", function (e) {
      alarm()
      e.clearSelection()
    })
  },
  compareFn() {
    if ($(".js-compare-btn").length > 0) {
      $(".js-compare-btn").each(function () {
        $(this).on("click", function () {
          if ($(".job-card.active").length < 2 || $(this).hasClass("active")) {
            var item = $(".job-card.active")
            var compareTxt = $(this)
              .parent()
              .parent()
              .find(".js-compare-txt")
              .text()
            var compareItem = `<div class="compare-popup__item js-compare-item" onClick="corpY(${
              item.length + 1
            })">
               <span class="txt txt--px18 txt--font400">${compareTxt}</span><span class="icon icon-font" data-icon-id="iconClose">    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001">
              <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
              L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
              c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
              l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
              L284.286,256.002z"/></svg></span></div>`
            $(".js-compare-popup").addClass("active")
            if ($(this).hasClass("active")) {
              $(this).removeClass("active")
              $(this).closest(".job-card").removeClass("active")
              $(".js-compare-item").each(function () {
                if (compareTxt == $(this).find(".txt").text()) {
                  $(this).remove()
                }
              })
            } else {
              $(this).addClass("active")
              $(this).closest(".job-card").addClass("active")
              $(".js-compare-items").append(compareItem)
            }
          }
          if ($(".job-card.active").length === 0) {
            $(".js-compare-popup").removeClass("active")
          }
        })
      })

      $("body").delegate(
        ".js-compare-items .js-compare-item",
        "click",
        function () {
          var thisText = $(this).find(".txt").text()
          $(".job-card").each(function () {
            if (thisText === $(this).find(".js-compare-txt").text()) {
              $(this).removeClass("active")
              $(this).find(".js-compare-btn").removeClass("active")
            }
          })
          $(this).remove()
          if ($(".js-compare-item").length === 0) {
            $(".js-compare-popup").removeClass("active")
          }
        }
      )
    }
  },
  isSafariFn() {
    var ua = navigator.userAgent.toLowerCase()
    const catchwordSection = document.querySelector(".catchword")
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
      } else {
        catchwordSection.classList.add("catchword--safari")
      }
    }
  },

  init: function () {
    app.load()
    app.iconSpriteFn()
    app.smoothScrollbarFn()
    app.cursorFn()
    app.heroCirclesFn()
    app.inspirationsStickyFn()
    app.movinWordsFn()
    app.headerSearchFn()
    app.heroLoadedFn()
    app.expertiseSliderFn()
    app.testimonialsSliderFn()
    app.indexTextAnimFn()
    app.resourcesCircleAnimFn()
    app.aboutImagesFn()
    app.aboutCirclesFn()
    app.select2OptionsFn()
    app.mainPopupsFn()
    app.mainSliderFn()
    app.modalFn()
    app.accordionFn()
    app.unLoadedFn()
    app.headerWhiteFn()
    app.similiarJobSliderFn()
    app.listGridViewFn()
    app.applyFilterFn()
    app.mobilemenubuttonsfn()
    app.menuButtonFn()
    app.letsChatFn()
    app.sortList()
    app.positionCardContentScroll()
    app.copyClipboardFn()
    app.compareFn()
    app.cookieAlertFn()
    app.isSafariFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
