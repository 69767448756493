import Prism from "prismjs"
import "prismjs/plugins/toolbar/prism-toolbar.js"
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min"
import "prismjs/components/prism-markup"
import "prismjs/components/prism-pug"
import "prismjs/components/prism-css"
import "prismjs/themes/prism-tomorrow.min.css"
import SmoothScroll from "smooth-scroll"
import Gumshoe from "gumshoejs"

export default function uikit() {
  var scroll = new SmoothScroll('.js-uikit-scroll a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    offset: 150,
    updateURL: false,
  })
  if (document.querySelector(".js-uikit-scroll")) {
    var spy = new Gumshoe(".js-uikit-scroll a", {
      offset: 150,
    })
    document.addEventListener(
      "gumshoeActivate",
      function (event) {
        var linkText = event.detail.link.innerHTML
        var linkUrl = event.detail.link.getAttribute("href")
        document.querySelector(".js-scroll-text").innerHTML = linkText
        // window.location.hash = linkUrl
      },
      false
    )
  }

  const uikitSearch = document.querySelector(".js-uikit-search")

  if (uikitSearch) {
    document.onkeydown = function (evt) {
      evt = evt || window.event
      var isEscape = false
      if ("key" in evt) {
        isEscape = evt.key === "Enter"
      } else {
        isEscape = evt.keyCode === 13
      }
      if (isEscape) {
        uikitSearch.focus()
      }
    }

    uikitSearch.addEventListener("keyup", (event) => {
      var uikitSearch, filter, ul, li, a, i, txtValue
      filter = event.target.value.toUpperCase()
      // ul = document.getElementById("myUL")
      li = document.querySelectorAll(".uikit__side-item")
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0]
        txtValue = a.textContent || a.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = ""
        } else {
          li[i].style.display = "none"
        }
      }
    })
  }
}
